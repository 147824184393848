import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('readingTime', function (elem, avgWordsPerMinute = 225) {
    return {
      time: 0,
      init() {
        if (!elem) return
        const element = document.querySelector(elem)
        if (!element) return

        // Get the text content of the element
        const textContent = element.textContent || element.innerText

        // Calculate the total character count
        const charCount = textContent.length

        // Calculate the average reading time
        const avgCharPerWord = 4.7
        const wordCount = charCount / avgCharPerWord
        this.time = Math.ceil(wordCount / avgWordsPerMinute)
      },
    }
  })
})()
