import Alpine from 'alpinejs'
import merge from 'deepmerge'

/**
 * @typedef {import('swiper').Swiper} Swiper
 * @typedef {import('swiper/types/swiper-options.d.ts').SwiperOptions} SwiperOptions
 */
export default (function () {
  let /** @type {Swiper} */ Plugin, Pagination, Navigation, Autoplay, EffectFade
  /** @type {SwiperOptions} */
  const defaults = {
    speed: 500,
    fadeEffect: {
      crossFade: true,
    },
  }

  Alpine.data('slider', function (conf = {}) {
    return {
      instance: null,
      count: 0,
      activeIndex: 0,
      locked: false,
      setIndex(index) {
        this.instance.slideTo(index)
      },
      init() {
        this.load()
      },
      async load() {
        const slider = this.$refs['slider'] || this.$el
        const [next, prev] = [this.$refs['next'], this.$refs['prev']]

        if (!Plugin) {
          ;[Plugin, [Pagination, Navigation, Autoplay, EffectFade]] =
            await Promise.all([
              import('swiper').then((mod) => mod.default),
              import('swiper/modules').then((mod) => [
                mod.Pagination,
                mod.Navigation,
                mod.Autoplay,
                mod.EffectFade,
              ]),
              import('swiper/css'),
            ])
        }

        /** @type {Swiper} */
        this.instance = new Plugin(
          slider,
          /** @type {SwiperOptions} */ ({
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            on: {
              afterInit: (instance) => {
                this.count = instance.slides.length
                this.locked =
                  !instance.allowSlideNext && !instance.allowSlidePrev
              },
              realIndexChange: (instance) => {
                this.activeIndex = instance.activeIndex
                this.locked =
                  !instance.allowSlideNext && !instance.allowSlidePrev
              },
            },
            ...(next && prev
              ? {
                  navigation: {
                    nextEl: next,
                    prevEl: prev,
                    disabledClass: 'opacity-50',
                  },
                }
              : {}),
            ...merge(defaults, conf),
          })
        )
      },
    }
  })
})()
