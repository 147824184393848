import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('measure', function (id, includeMargin = false, threshold = 100) {
    return {
      init() {
        let timeoutId = null
        const el = this.$root
        const measure = () => {
          let height = el.offsetHeight

          if (includeMargin) {
            const style = window.getComputedStyle(el)
            height += parseInt(style.marginTop) + parseInt(style.marginBottom)
          }

          document.documentElement.style.setProperty(
            `--${id}`,
            height + 'px'
          )
        }

        measure()
        try {
          const obs = new ResizeObserver(() => {
            // Clear the timeout if it exists
            if (timeoutId) clearTimeout(timeoutId)

            // Set a new timeout
            timeoutId = setTimeout(measure, threshold)
          })

          ;[el, document.body].forEach(el => {
            obs.observe(el)
          })
        } catch (error) {
          // no ResizeObserver
        }
      },
    }
  })
})()
