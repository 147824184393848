import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

import 'modules/measure'
import 'modules/accordion'
import 'modules/slider'
import 'modules/readingTime'
import 'modules/form'
import 'modules/dropdown'

Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()
